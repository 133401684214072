import { Routes, Route, Navigate } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Pricing from "./screens/Pricing";
import Contact from "./screens/Contact";
import Terms from "./screens/Terms";
import Privacy from "./screens/Privacy";

function App() {
  const streamLoginUrl =
    "https://stream.sparkmysport.com/login/64eee3aa-ab73-11ef-a33e-068dc392bf6d";

  // Custom redirect component for external URLs
  const ExternalRedirect = ({ to }) => {
    window.location.href = to;
    return null;
  };

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Page>
              <Home />
            </Page>
          }
        />
        <Route
          path="pricing"
          element={
            <Page>
              <Pricing />
            </Page>
          }
        />
        <Route
          path="contact"
          element={
            <Page>
              <Contact />
            </Page>
          }
        />
        <Route
          path="terms"
          element={
            <Page page="terms">
              <Terms />
            </Page>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <Page page="terms">
              <Privacy />
            </Page>
          }
        />
        {/* External redirect routes */}
        <Route
          path="campaign/*"
          element={<ExternalRedirect to={streamLoginUrl} />}
        />
        <Route
          path="event/*"
          element={<ExternalRedirect to={streamLoginUrl} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
