import React, { useState } from "react";
import cn from "classnames";
import Modal from "react-modal";
import styles from "./VideoCard.module.sass";
import DownloadModal from "../../../components/ModalDownloader";
import BasicPriceCard from "../../BasicPriceCard";

// Ensure that the app element is set for accessibility
Modal.setAppElement("#root");

const VideoCard = ({ item, reverse }) => {
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openVideoModal = () => {
    if (!item.title) {
      setVideoModalIsOpen(true);
    }
  };

  const closeVideoModal = () => {
    setVideoModalIsOpen(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleClick = () => {
    window.location.href = 'https://smsweb.sparkmysport.com/login';
  }

  return (
    <div className={cn(styles.usercard, { [styles.reverse]: reverse })}>
      <div className={styles.videocontainer}>
        <div
          className={styles.videosection}
          onClick={openVideoModal}
          style={{
            backgroundImage: `url(${item.thumbnailUrl})`,
            backgroundSize:'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "center",
            cursor: "pointer",
            position: "relative",
          }}
        >
          {!item.title && (
            <div className={styles.playbutton}>
              <img src="/images/content/play.png" alt="Play Button" />
            </div>
          )}
        </div>
        <div className={styles.textsection}>
          <div className={styles.title}></div>
          <div className={cn("h2", styles.header)}>{item.header}</div>
          <div className={styles.content}>{item.content}</div>
          <div className={styles.buttonContainer}>
            {!item.title && (
                <button className={styles.watchvideobutton} onClick={openVideoModal}>
                  Watch video
                </button>
              )}
            <button className={styles.downloadappbutton} onClick={handleClick}>
              Log In
            </button>
          </div>
        </div>
      </div>
      <div  className={styles.priceData}>
          <BasicPriceCard data={item.priceData} />
      </div>
      <Modal
        isOpen={videoModalIsOpen}
        onRequestClose={closeVideoModal}
        contentLabel="Watch Video"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.videoWrapper}>
          <video className={styles.video} controls autoPlay>
            <source src={item.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <button className={styles.closeButton} onClick={closeVideoModal}>
          Close
        </button>
      </Modal>
      <DownloadModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default VideoCard;